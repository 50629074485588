<template>
  <sun-input
    :id="id"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="value"
    :error="!!internalError"
    :text-error="internalError || null"
    :auto-complete="autoComplete"
    :auto-validate="autoValidate"
    :autofocus="autofocus"
    :cancel-error="cancelError"
    :class-input="classInput"
    :clearable="clearable"
    :max="max"
    :maxlength="maxlength"
    :min="min"
    :min-strength="minStrength"
    :minlength="minlength"
    :pattern="pattern"
    :readonly="readonly"
    :required="required"
    :show-password="showPassword"
    :type="type"
    :validate-on-blur="validateOnBlur"
    @change="onChange"
    @blur="checkErrorsAndPropagateEvent"
    @input="onInput"
    @enter="onEnter"
  />
</template>

<script>
import { ERRORS } from '@/i18n/forms/errors';

export default {
  name: 'AsterixInput',
  props: {
    id: { type: String, default: () => undefined },
    name: {
      type: String,
      default: () => undefined,
    },
    disabled: {
      type: Boolean,
      default: () => undefined,
    },
    required: {
      type: Boolean,
      default: () => undefined,
    },
    readonly: {
      type: Boolean,
      default: () => undefined,
    },
    autofocus: {
      type: Boolean,
      default: () => undefined,
    },
    cancelError: {
      type: Boolean,
      default: () => undefined,
    },
    type: {
      type: String,
      default: () => 'text',
    },
    placeholder: {
      type: String,
      default: () => undefined,
    },
    pattern: {
      type: [String, RegExp],
      default: () => undefined,
    },
    minlength: {
      type: Number,
      default: () => undefined,
    },
    maxlength: {
      type: Number,
      default: () => undefined,
    },
    min: {
      type: Number,
      default: () => undefined,
    },
    max: {
      type: Number,
      default: () => undefined,
    },
    autoValidate: {
      type: Boolean,
      default: () => false,
    },
    autoComplete: {
      type: Boolean,
      default: () => undefined,
    },
    validateOnBlur: {
      type: Boolean,
      default: () => undefined,
    },
    showPassword: {
      type: Boolean,
      default: () => undefined,
    },
    clearable: {
      type: Boolean,
      default: () => undefined,
    },
    minStrength: {
      type: Number,
      default: () => undefined,
    },
    classInput: {
      type: [String, Array, Object],
      default: () => '',
    },
    value: {
      type: [String, Number],
      default: () => undefined,
    },
    error: {
      type: [String, Boolean],
      default: () => false,
    },
    patternErrorMessage: {
      type: String,
      default: () => '',
    },
    maxlengthError: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    internalError: null,
  }),
  watch: {
    error(newError) {
      this.internalError = newError;
    },
    required() {
      this.internalError = null;
    },
  },
  created() {
    this.internalError = this.error;
  },
  methods: {
    onChange(event) {
      this.checkError(event || {});
      this.$emit('change', event);
    },
    onInput(event) {
      this.$emit('input', event);
    },
    onEnter(event) {
      this.$emit('enter', event);
    },
    checkErrorsAndPropagateEvent(event) {
      this.checkError(event || {});
      this.$emit('blur', event);
    },
    checkError({ validation }) {
      const firstError = !validation.isValid && validation.errors?.length ? validation.errors[0] : null;
      this.internalError = null;
      if (firstError) {
        switch (firstError) {
          case 'minlength':
            this.internalError = ERRORS.en.minLength(this.minlength);
            break;
          case 'maxlength':
            this.internalError = this.maxlengthError || ERRORS.en.maxLength(this.maxlength);
            break;
          case 'field-required':
            this.internalError = ERRORS.en.required;
            break;
          case 'validation-from-parent':
            this.internalError = this.error || null;
            break;
          case 'value-smaller-than-min':
            this.internalError = ERRORS.en.min(this.min);
            break;
          case 'value-bigger-than-max':
            this.internalError = ERRORS.en.max(this.max);
            break;
          case 'invalid-url':
            this.internalError = ERRORS.en.validUrl;
            break;
          case 'no-match-pattern':
            this.internalError = this.patternErrorMessage || ERRORS.en.pattern;
            break;
          case 'invalid-password':
            this.internalError = this.patternErrorMessage || ERRORS.en.moreSixCharacters;
            break;
          case 'invalid-email':
            this.internalError = ERRORS.en.validEmail;
            break;
        }
      }
      this.$emit('error', { type: firstError, text: this.internalError });
    },
  },
};
</script>
