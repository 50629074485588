var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sun-input", {
    attrs: {
      id: _vm.id,
      name: _vm.name,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      value: _vm.value,
      error: !!_vm.internalError,
      "text-error": _vm.internalError || null,
      "auto-complete": _vm.autoComplete,
      "auto-validate": _vm.autoValidate,
      autofocus: _vm.autofocus,
      "cancel-error": _vm.cancelError,
      "class-input": _vm.classInput,
      clearable: _vm.clearable,
      max: _vm.max,
      maxlength: _vm.maxlength,
      min: _vm.min,
      "min-strength": _vm.minStrength,
      minlength: _vm.minlength,
      pattern: _vm.pattern,
      readonly: _vm.readonly,
      required: _vm.required,
      "show-password": _vm.showPassword,
      type: _vm.type,
      "validate-on-blur": _vm.validateOnBlur,
    },
    on: {
      change: _vm.onChange,
      blur: _vm.checkErrorsAndPropagateEvent,
      input: _vm.onInput,
      enter: _vm.onEnter,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }